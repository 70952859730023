import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")
  const _component_KTCreateAccountModal = _resolveComponent("KTCreateAccountModal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Create Account Modal Example",
      description: "Click on the below buttons to launch <br/>create account modal example.",
      image: "media/illustrations/statistics.png",
      "button-text": "Create Account",
      "modal-id": "kt_modal_create_account"
    }),
    _createVNode(_component_KTCreateAccountModal)
  ], 64))
}